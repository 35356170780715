
// lib
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UiMediaUpload extends Vue {
  @Prop({
    type: String,
    required: true
  }) readonly value!: string

  @Prop({
    type: Array,
    default: () => []
  }) readonly rules!: []

  handleUploadMedia (): void {
    const url = window.prompt('Media url')

    if (url) {
      this.$emit('input', url)
    }
  }
}
