// lib
import axios, { AxiosResponse } from 'axios'

// app
import Response from '@/app/shared/models/response'

export default class JournalSerivce {
  static async checkLink (link: string, id: number | string | null): Promise<boolean> {
    const response: AxiosResponse<Response<{ unique: boolean }>> = await axios.post('/pages/check-link', {
      link,
      id
    })

    return response.data.data.unique
  }
}
