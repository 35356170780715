// lib
import { Vue, Component } from 'vue-property-decorator'

// app
import { generateUniqueId } from '@/app/shared/utils/unique'

@Component
export default class DataMixin<T extends { $uuid?: string }> extends Vue {
  $getInitial!: () => T

  dataMixin_data: T[] = []

  checkForm (): boolean {
    if (!this.$data.form) {
      console.error('Для DataMixin требуется form данные')
      return false
    }

    return true
  }

  dataMixin_setInitialForm (initial: () => T): void {
    this.$data.$getInitial = initial
  }

  dataMixin_clearForm (): void {
    if (!this.checkForm()) {
      return
    }

    this.$data.form = this.$data.$getInitial()
  }

  dataMixin_handleAddItem (): void {
    if (!this.checkForm()) {
      return
    }

    this.$data.form.$uuid = generateUniqueId()
    this.dataMixin_data.push(this.$data.form)
    this.dataMixin_clearForm()
  }

  dataMixin_handleDeleteItem ($uuid: string): void {
    this.dataMixin_data = this.dataMixin_data.filter(v => v.$uuid !== $uuid)
  }

  dataMixin_handleMovePrev ($uuid: string): void {
    const foundIndex = this.dataMixin_data.findIndex(v => v.$uuid === $uuid)

    if (foundIndex === 0) {
      return
    }

    const prev = this.dataMixin_data[foundIndex - 1]
    this.$set(this.dataMixin_data, foundIndex - 1, this.dataMixin_data[foundIndex])
    this.$set(this.dataMixin_data, foundIndex, prev)
  }

  dataMixin_handleMoveNext ($uuid: string): void {
    const foundIndex = this.dataMixin_data.findIndex(v => v.$uuid === $uuid)

    if ((this.dataMixin_data.length - 1) === foundIndex) {
      return
    }

    const next = this.dataMixin_data[foundIndex + 1]
    this.$set(this.dataMixin_data, foundIndex + 1, this.dataMixin_data[foundIndex])
    this.$set(this.dataMixin_data, foundIndex, next)
  }
}
