export interface IConstructorFile {
  type: 'block' | 'component'
  name: string
  title: string
  icon: string
  importedPath: () => Promise<any>
}

export default class ConstructorFile implements IConstructorFile {
  type: 'block' | 'component' = 'component'
  name = ''
  title = 'Без имени'
  icon = 'mdi-home'
  importedPath = () => Promise.resolve('')

  constructor (props?: IConstructorFile) {
    Object.assign(this, props)
  }
}
