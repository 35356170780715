
// lib
import { Vue, Component, Prop } from 'vue-property-decorator'

// app
import ConstructorFile from '../models/ConstructorFile'

@Component
export default class AppConstructorBtn extends Vue {
  @Prop({
    type: Array,
    default: () => []
  }) readonly registeredFiles!: ConstructorFile[]
}
