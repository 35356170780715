
// lib
import { Vue, Component, Prop } from 'vue-property-decorator'

// app
import AbstractConstructor from '@/app/constructor/shared/models'
import ConstructorBlock from '@/app/constructor/shared/models/ConstructorBlock'
import ConstructorFile from '@/app/constructor/shared/models/ConstructorFile'
import AppConstructorBtn from './shared/components/app-constructor-btn.vue'

@Component({
  components: {
    SmartConstructorOther: () => import('./index.vue'),
    AppConstructorBtn
  }
})
export default class SmartConstructor extends Vue {
  @Prop({
    type: ConstructorBlock,
    required: true
  }) readonly value!: ConstructorBlock

  @Prop({
    type: Array,
    default: () => []
  }) readonly registeredFiles!: ConstructorFile[]

  get files (): any {
    let result: any = {}

    this.registeredFiles.forEach(v => result[v.name] = v.importedPath)

    return result
  }

  handleAddItem (data: { $uuid: string, index: number, file: ConstructorFile }): void {
    this.$emit('add-item', {
      $uuid: data.$uuid,
      index: data.index,
      file: data.file
    })
  }

  handleEmitValue (value: AbstractConstructor): void {
    this.$emit('input', value)
  }

  handleDeleteItem (data: { $uuid: string, index: number }): void {
    this.$emit('delete-item', {
      $uuid: data.$uuid,
      index: data.index
    })
  }
}
